import React from 'react';
import './whatsappIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsappIcon = () => {
    const handleClick = () => {
        // Handle the click event to open WhatsApp chat
        window.open('https://wa.me/+2348112243792', '_blank');
    }

    return (
        <div className="whatsapp-chat-container" onClick={handleClick}>
            <div className="whatsapp-icon">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </div>
            <div className="whatsapp-chat-label">Contact Us</div>
        </div>
    );
}

export default WhatsappIcon;
