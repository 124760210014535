import React from 'react';
import './testimonials.css';
import client1 from '../../image/avatar.jpeg';
import client2 from '../../image/avatar2.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const testimonials = [
  {
    id: 1,
    name: 'Emmanuel Banks',
    profession: 'Graphic Designer',
    testimonial: 'LaundryHighland has been a game-changer for me. Their service is reliable, efficient, and my clothes have never looked better. Highly recommended!',
    image: client2,
  },
  {
    id: 2,
    name: 'Clara Stephen',
    profession: 'Marketing Specialist',
    testimonial: 'I have used LaundryHighland services for several months now, and I am extremely satisfied. They always deliver on time, and my clothes are impeccably cleaned. Great job!',
    image: client1,
  },
  {
    id: 3,
    name: 'John Michael',
    profession: 'Business Owner',
    testimonial: 'LaundryHighland provides excellent service. The convenience and quality are unmatched. My clothes are always returned in perfect condition. I am very happy with their services.',
    image: client2,
  },
];

const Testimonials = () => {
  return (
    <div id='reviews' className="testimonial-section">
      <h2>OUR CLIENTS SAY</h2>
      <div className="testimonial-container">
        {testimonials.map((client) => (
          <div className="testimonial-card" key={client.id}>
            <div className="client-image">
              <img src={client.image} alt={client.name} />
            </div>
            <h3>{client.name}</h3>
            <p className="profession">{client.profession}</p>
            <p className="testimonial">{client.testimonial}</p>
            <div className="stars">
              {[...Array(5)].map((star, index) => (
                <FontAwesomeIcon key={index} icon={faStar} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
