import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <h2>Laundry Highland</h2>
                    <p>Visit your nearest Laundry Highland branch today and experience the difference!</p>
                    <div className="opening-hours">
                        <p><strong>Opening Hours:</strong></p>
                        <p>Mon - Sat, 8AM - 5PM</p>
                        <p>Sunday: Closed</p>
                    </div>
                </div>
                <div className="footer-column">
                    <h2>Get In Touch</h2>
                    <p><i className="fas fa-map-marker-alt"></i> 1, Ojomon Avenue, Off Etete Road, Behind El Classico, Off Adesuwa Road, Benin City</p>
                    <p><i className="fas fa-phone"></i> +2348112243792</p>
                    <p><i className="fas fa-envelope"></i> info@laundryhighland.com</p>
                    <div className="social-icons">
                        <a href="www.facebook.com"><i className="fab fa-facebook-f"></i></a>
                        <a href="www.twitter.com"><i className="fab fa-twitter"></i></a>
                        <a href="www.instagram.com"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
                <div className="footer-column">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#services">Our Services</a></li>
                        <li><a href="#gallery">Our Gallery</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h2>Make a Complaint</h2>
                    <p>We value your feedback and are here to address any concerns you may have. Please let us know how we can improve our services.</p>
                    <form>
                        <textarea id='textarea' placeholder="Your Complaint" />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© laundryhighland. All Rights Reserved. </p>
                <div className="footer-links">
                    <a href="#privacy">Privacy</a>
                    <a href="#Terms">Terms</a>
                    <a href="#faq">FAQs</a>
                    <a href="#help">Help</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
