import React, { useState } from 'react';
import './header.css';

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const whatsappNumber = '+2348112243792'; // Replace with your WhatsApp number
  const predefinedMessage = 'Hello, I want to make further enquiry on your services';

  return (
    <div className='header'>
      {/* widescreen */}
      <div className='headerr'>
        <header id="header">
          <div className="logo">
            <img src={require('../../image/LH logo color.png')} alt="LundryHighland." />
          </div>
          <button className="nav-toggle" onClick={toggleNavVisibility}>
            &#9776; {/* Unicode character for hamburger icon */}
          </button>
          <nav className={`nav ${isNavVisible ? 'visible' : ''}`}>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#reviews">Reviews</a></li>
              <li><a href="#blog">Blogs</a></li>
              <li>
                <a
                  className="book-online"
                  href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(predefinedMessage)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book Online
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      {/* smaller screen */}
      <div className='headeer'>
        <header id="headeer" className="alt">
          <div className="logo">
            <a href="index.html">
              <img src={require('../../image/LOGO.png')} alt="Spectral Logo" />
            </a>
          </div>
          <nav id="nav">
            <ul>
              <li className="special">
                <a href="#menu" className="menuToggle" onClick={toggleMenu}><span>Menu</span></a>
              </li>
            </ul>
          </nav>
        </header>
        <div id="menu" className={menuOpen ? 'open' : ''}>
          <span className="close" onClick={toggleMenu}>&times;</span>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#reviews">Reviews</a></li>
            <li><a href="#blog">Blogs</a></li>
          </ul>
        </div>
      </div>
      <section id="banner">
        <div className="content">
          <h2>Experience the Peak of Cleanliness with Laundry Highland</h2>
          <p>Delivering unparalleled laundry and cleaning services across Edo State</p>
          <a
            className="cta-button"
            href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(predefinedMessage)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Started
          </a>
        </div>
        <div className="image-container">
          <img id='image' src={require('../../image/homee.png')} alt="Dry Cleaning Service" />
        </div>
      </section>
    </div>
  );
};

export default Header;
