import React, { useState, useEffect } from 'react';
import BlogCard from './blogcard';
import './blogpage.css'
import Image2 from '../../image/airdrying.avif';
import Image3 from '../../image/dos and dont.jpg';
import Image4 from '../../image/detergent.avif';
import Image5 from '../../image/fabric.jpg';
import Image6 from '../../image/stain.jpg';
import Image1 from '../../image/busy.jpg';

const blogData = [
  {
    id: 1,
    date: '01 Jan',
    title: 'Air-Drying vs. Machine Drying: Which is Best for Your Clothes',
    description: 'Explore the benefits and drawbacks of air-drying and machine drying in this comprehensive guide. Learn which method is best for your clothes, lifestyle, and the environment. Plus, discover how a professional laundry service can simplify your laundry routine.',
    imageUrl: Image2
  },
  {
    id: 2,
    date: '01 Jan',
    title: "Efficient Washing Machine Usage: Dos and Don'ts ",
    description: "Master the art of efficient washing machine usage with this comprehensive guide. Learn the key dos and don'ts to ensure your clothes come out clean, and your machine stays in good condition. Need a break from laundry? Discover the value of our professional laundry services.",
    imageUrl: Image3
  },
  {
    id: 3,
    date: '01 Jan',
    title: 'Choosing the Perfect Detergent: An Essential Guide for Your Laundry Needs',
    description: 'Unlock the secrets of choosing the right detergent for your laundry needs. Explore this comprehensive guide that simplifies the process, offers practical tips, and paves the way for the perfect laundry experience with our top-notch laundry service.',
    imageUrl: Image4
  },
  {
    id: 4,
    date: '01 Jan',
    title: 'Laundry Tips for Delicate Fabrics',
    description: 'Learn the best practices for washing delicate fabrics to keep them looking new and extending their lifespan. Discover how a professional laundry service can handle delicate items with care.',
    imageUrl: Image5
  },
  {
    id: 5,
    date: '01 Jan',
    title: 'How to Remove Stubborn Stains from Clothes',
    description: 'Get rid of stubborn stains with these effective tips and tricks. Find out how a professional laundry service can tackle even the toughest stains for you.',
    imageUrl: Image6
  },
  {
    id: 6,
    date: '01 Jan',
    title: 'Laundry Tips for Busy Professionals',
    description: 'Learn how busy professionals can manage their laundry efficiently. Discover time-saving tips and the benefits of using a professional laundry service.',
    imageUrl: Image1
  }
];

function BlogPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setVisibleCount(1);
      } else {
        setVisibleCount(3);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(blogData.length / visibleCount));
    }, 3000); // Change slide every 5 seconds

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, [visibleCount]);

  const visibleBlogs = blogData.slice(currentIndex * visibleCount, currentIndex * visibleCount + visibleCount);

  return (
    <div className="blog-page">
      <div className="heaader">
        <h1>LATEST ARTICLES FROM OUR BLOG POST</h1>
      </div>
      <div className="blog-list">
        {visibleBlogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
}

export default BlogPage;