import React from 'react';
import './about.css';

const About = () => {
  return (
    <div id='about'>
    <div className="container">
      <div className="experience-section">
        <div className="experience-content">
          <div className="experience-years">
            <div className="experience-badge">
              <img src={require('../../image/badge-removebg-preview.png')} alt="Badge" />
            </div>
            <h1>3</h1>
            <p>Years Experience</p>
          </div>
        </div>
      </div>
      <div className="about-section">
        <h3>LEARN ABOUT US</h3>
        <h2>We Provide The Best Cleaning Services</h2>
        <p>
        Laundry Highland is dedicated to providing top-tier laundry and cleaning services throughout Edo State, Nigeria. With three strategically located branches, we ensure that cleanliness and convenience are always within your reach.
        </p>
        <p>
        Our commitment to excellence is reflected in our meticulous attention to detail and our unwavering dedication to customer satisfaction. Whether you need residential laundry services or comprehensive industrial cleaning solutions, Laundry Highland is your trusted partner in maintaining a pristine environment
        </p>
        <button className="learn-more">Learn More</button>
      </div>
    </div>
    </div>
  );
};

export default About;
