import React, { useState, useEffect, useCallback } from 'react';
import './service.css';
import Washing from '../../image/laundry.jpeg';
import Ironing from '../../image/Laundry4.jpeg'; 
import laundryCleaning from '../../image/laundry service.jpeg';
import industrialCleaning from '../../image/industrial.jpg';
import FumigationService from '../../image/fumigation.jpeg'
import deliveryServices from '../../image/laundry2.jpeg';

const services = [
  { title: 'Washing & Folding', img: Washing },
  { title: 'Ironing', img: Ironing },
  { title: 'Industrial Cleaning', img: industrialCleaning },
  { title: 'Dry Cleaning', img: laundryCleaning },
  { title: 'Delivery Services', img: deliveryServices },
  { title: 'Fumigation', img: FumigationService },
];
const Services = () => {
  const [current, setCurrent] = useState(0);
  const length = services.length;

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 4 ? 0 : current + 1);
  }, [current, length]);

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 4 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    
    <section id='services' className="services">
      <div className="services-container">
        <div className="services-header">
          <h4>OUR SERVICES</h4>
        <h2>What We Offer</h2>
      </div>
      <div className="services-slider">
          <button className="left-arrow" onClick={prevSlide}>&#10094;</button>
          <div className="slide-wrapper">
            {services.slice(current, current + 4).map((service, index) => (
              <div key={index} className="slide">
                <div className="service-card">
                  <div className="service-icon">
                  
                    <img src={service.img} alt={service.title} />
                    <h3>{service.title}</h3>
                  </div>
                 
                </div>
              </div>
            ))}
          </div>
          <button className="right-arrow" onClick={nextSlide}>&#10095;</button>
        </div>
      </div>
    </section>
  );
};

export default Services;