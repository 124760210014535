import React from 'react';
import './location.css'; // Import the CSS file

const Locations = () => {
    return (
        <div className="locations-container">
            <div className="location-item">
                <h2>COUNTRY HOME BRANCH</h2>
                <p>📍 Shop 12, Idolor Complex Off Country Home Road, Benin City</p>
            </div>
            <div className="location-item">
                <h2>OKO CENTRAL BRANCH</h2>
                <p>📍 8, Emuze Street, Off Central Road, Benin City</p>
            </div>
            <div className="location-item">
            <h2>ETETE BRANCH</h2>
                <p>📍 1, Ojomon Avenue, Off Etete Road, Behind El Classico, Off Adesuwa Road, Benin City</p>
            </div>
        </div>
    );
}

export default Locations;
