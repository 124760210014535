// src/components/BlogCard.js
import React from 'react';
import './blogcard.css';

function BlogCard({ blog }) {
  return (
    <div id='blog'>
    <div className="blog-card">
      <img src={blog.imageUrl} alt={blog.title} />
      <div className="blog-content">
        <span className="blog-date">{blog.date}</span>
        <h2 className="blog-title">{blog.title}</h2>
        <p className="blog-description">{blog.description}</p>
        <button className="read-more">Read More</button>
      </div>
      </div>
    </div>
  );
}

export default BlogCard;
