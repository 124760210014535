import { Route, Routes } from 'react-router';
import Homepage from './Homepage/homepage';
import './App.css';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Homepage></Homepage>}>

        </Route>
      </Routes>
   
    </div>
  );
}

export default App;
