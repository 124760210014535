import React from 'react';
import './core-value.css';

const Corevalue = () => {
  const cards = [
    { id: 1, title: "Exceptional Quality", content: "Our laundry service ensures your clothes are cleaned with the highest standards, giving them a fresh and new look every time." },
    { id: 2, title: "Convenience", content: "Enjoy the convenience of our pick-up and delivery service, saving you time and effort.", image: "path/to/image.jpg" },
    { id: 3, title: "Affordable Prices", content: "We offer competitive pricing without compromising on quality, ensuring you get the best value for your money." },
    { id: 4, title: "Professional Service", content: "Our team of experienced professionals handle your laundry with care and precision." },
    { id: 5, title: "Eco-Friendly Practices", content: "We use environmentally friendly detergents and energy-efficient machines to reduce our carbon footprint." },
    { id: 6, title: "Customer Satisfaction", content: "Our priority is your satisfaction. We strive to provide excellent service that meets and exceeds your expectations." },
  ];
  return (
    <div className="why-choose-us">
      <h2>WHY CHOOSE US</h2>
      <p>Our laundry service offers exceptional quality, convenience, and eco-friendly practices to ensure your clothes are always fresh and clean.</p>
      <div className="card-container">
        {cards.map((card, index) => (
          <div key={card.id} className="card">
            <div className="card-content">
              <h3>{card.title}</h3>
              <p>{card.content}</p>
            </div>
            <div className="card-number">{index + 1}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Corevalue;
