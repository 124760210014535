import React from 'react';
import './contactinfo.css';

const ContactInfo = () => {
  return (
    <div className="contact-info-container">
      <div className="infoo-box">
        <div className="info-icon">
          <i className="fas fa-map-marker-alt"></i>
        </div>
        <div className="info-content">
          <h5>Head Office</h5>
          <p>1, Ojomon Avenue, Off Etete Road, Behind El Classico,</p>
          <p> Off Adesuwa Road, Benin City</p>
        </div>
      </div>
      <div className="infoo-box">
        <div className="info-icon">
          <i className="fas fa-envelope"></i>
        </div>
        <div className="info-content">
          <h5>Email Us</h5>
          <p>info@laundryhighland.com</p>
        </div>
      </div>
      <div className="infoo-box">
        <div className="info-icon">
          <i className="fas fa-phone-alt"></i>
        </div>
        <div className="info-content">
          <h5>Call Us</h5>
          <p>+2348112243792</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
