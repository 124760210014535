import React from 'react';
import './homepage.css';
import Header from './header/header';
import Service from './service/service';
import Testimonials from './testimonials/testimonials';
import About from './About/about';
import Corevalue from './About/corevalue';
import Contactinfo from './contact/contactinfo';
import Footer from './footer/footer';
import WhatsappIcon from './whatsapp/whatsapicon';
import BlogPage from './blog/blogpage';
import Locations from './contact/location';



function Homepage() {
    return (
        <div id='home'>
            <Header></Header>
            <Contactinfo></Contactinfo>
            <About></About>
            <Service></Service>
            <Corevalue></Corevalue>
            <Testimonials></Testimonials>
            <BlogPage></BlogPage>
            <Locations></Locations>
            <Footer></Footer>
            <WhatsappIcon></WhatsappIcon>
         

        </div>
    )
}

export default Homepage;